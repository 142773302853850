<template>
  <div class="text-left py-3">
    <!-- <BaseHeader :title="'Clients'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>

      <!-- {{clients}} -->
      <div class=" mt-2 border-0">
        <div class=" card mb-2 d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1" style>
          <h2 class="card-title w-50 font-weight-black text-capitalize">
            Clients
          </h2>
          <!-- <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter clients"
            name="url"
            type="text"
            hide-details
          /> -->
        </div>


        <div class="card orderlisting--card py-2">
          <div class=" mx-0  pref-table-custom ">

            <div  class=" order-listing-header    row  p-3 mx-sm-0 mx-1 ">  
              <div class="col-1 text-left text--dark--custom "> ID </div>
              <div class="col-2 text-left text--dark--custom ">Full Name</div>
              <div class="col-2 text-left text--dark--custom">Email</div>
              <div class="col-2 text-left text--dark--custom"> Phone</div>
              <div class="col-1 text-left text--dark--custom"> Country</div>
              <div class="col-2 text-left text--dark--custom"> Site</div>
              <div class="col-1 text-left text--dark--custom"> Status</div>
              <div class="col-1 text-left text--dark--custom">Action</div>
          </div>


          <div v-if="clients.length > 0" class="">
          <div v-for="(item, index) in clients" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
              <div class="col-1 d-flex align-items-start justify-content-start text-left">	
                <router-link :to="{ name: 'ClientProfile', params: { id: item.id } }" style="font-weight:500!important" class="px-2"
                  >{{ item.id || "" }}</router-link>
                
              </div>
              <div class="col-2 text-left">
                <router-link :to="{ name: 'ClientProfile', params: { id: item.id } }"  style="font-weight:500!important" class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.names || "" }} </router-link>
              </div>

              <div class="col-2 text-left">
                <div style="font-weight:500!important" class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.email || "" }} </div>
              </div>
              <!-- <div class="col-2 text-left">{{ item.email || "" }}</div> -->
              <div style="font-weight:500!important" class="col-2 text-left">  
                {{item.phone}}
                
              </div>

              <div style="font-weight:500!important" class="col-1 text-left">  
                {{item.country}}
                
              </div>

              <div style="font-weight:500!important" class="col-2 text-left">  
                {{item.url}}
                
              </div>

              <div style="font-weight:500!important" class="col-1 text-left">  
                <v-chip :color="item.status ? 'primary' : 'error'" small outlined>{{
                  item.status ? "Active" : "Inactive"
                }}</v-chip>
                
              </div>

              
              <div class="col-1 text-left  ">
                <v-btn
              fab
              elevation="0"
              x-small
              :to="{ name: 'ClientProfile', params: { id: item.id } }"
            >
            <v-icon scale="1.3" name="eye" color="black" />
            </v-btn>
              </div>
          </div>
          </div>

          </div>
          <div class="p-3 d-flex align-items-center justify-content-center w-100 "  v-if="clients.length < 1">
            
                <strong class="order_empty  text-center" >No Clients</strong>
            
          </div>
        </div>



        <!-- pagination -->
        <div class="card d-flex align-items-center flex-row w-100 justify-content-between p-3 mb-3" v-if="clients.length > 0">
          <p class="text-left">
            From {{ filters.from || "" }} to {{ filters.to || "" }} of
            {{ filters.total || "" }} Clients
          </p>
            <div data-v-2bb62016="" class="btn-group">
              <button   @click="navigate(filters.prev_page_url)"  v-if="filters.prev_page_url" class="btn btn-outline-primary btn-lg">← &nbsp; Prev</button>
              <button   class="btn btn-primary btn-lg">On Page {{ filters.current_page || "" }}</button>
              <button   @click="navigate(filters.next_page_url)" v-if="filters.next_page_url" class="btn btn-outline-primary btn-lg">Next &nbsp; →</button>
            </div>
          
        </div>
        <!-- ./pagination -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Clients",
  computed: {
    ...mapState("clientusers", ["loading", "clients", "headers", "filters"]),
  },
  data() {
    return {
      countries: [],
    };
  },

  methods: {
    ...mapActions("clientusers", ["_getClients", "_navigate"]),
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this._getClients()
      .then(() => {
        console.log("done");
      })
      .catch((err) => console.log(err));
  },
};
</script>